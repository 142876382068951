<template>
  <div class="list-detail d-flex flex-column scroll-container">
    <v-container class="d-flex flex-column scroll-container" v-if="list">
      <v-row class="list-detail-header" align="center">
        <v-col cols="12" sm="7" md="7" lg="8">
          <h2 class="list-name primary--text text-sm-body-1 text-lg-h2">
            <strong>Lista: </strong>{{ list.name }}
            <span class="list-size">
              ({{ $tc("list.listSize", list.wishlistItems.length) }})</span
            >
          </h2>
        </v-col>
        <!-- <v-col cols="12" md="6" class="d-flex flex-column flex-md-row"> -->
        <v-col cols="12" sm="5" md="5" lg="4">
          <div
            class="d-flex justify-end"
            :class="{ 'flex-column': $vuetify.breakpoint.xs }"
          >
            <v-btn
              outlined
              color="primary"
              class="bg-white"
              depressed
              :small="$vuetify.breakpoint.mdAndDown"
              :disabled="
                list.wishlistItems.length + list.wishlistInactiveItems.length ==
                  0
              "
              @click="emptyList()"
            >
              SVUOTA LISTA
            </v-btn>
            <v-btn
              color="secondary"
              class="add-all-to-card"
              depressed
              :small="$vuetify.breakpoint.mdAndDown"
              @click="addAllToCart"
              :disabled="
                list.wishlistItems.length + list.wishlistInactiveItems.length ==
                  0
              "
            >
              {{ $t("list.addAll") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="table-header text-uppercase pt-3"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-col cols="12" md="6" class="pl-3">
          {{ $t("list.header.description") }}
        </v-col>
        <v-col
          cols="4"
          md="2"
          class="table-column justify-s hidden-sm-and-down text-center"
        >
          {{ $t("list.header.price") }}
        </v-col>
        <v-col cols="3" class="table-column justify-center hidden-sm-and-down">
          <!-- <strong>Quantità</strong> -->
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <div
        class="detail-table t-list-detail pa-1 pa-sm-4 rounded-lg"
        v-if="list.wishlistItems.length + list.wishlistInactiveItems.length > 0"
      >
        <v-list class="t-detail-list">
          <ListItem
            v-for="item in list.wishlistItems"
            v-bind:key="item.itemId"
            :item="item"
            :isActive="true"
            @removeItem="removeFromList"
            @update="updateList"
            :list="list"
          ></ListItem>
          <!-- :itemId="item.itemId" -->
          <!-- :product="item.product" -->
          <ListItem
            v-for="item in list.wishlistInactiveItems"
            v-bind:key="item.itemId"
            :item="item"
            @removeItem="removeFromList"
            :isActive="false"
            :list="list"
          ></ListItem>
          <!-- :product="item.product"
            :itemId="item.itemId" -->
        </v-list>
      </div>
      <v-card
        light
        depresses
        elevation="0"
        v-if="
          list.wishlistItems.length + list.wishlistInactiveItems.length == 0
        "
        class="mt-3 pa-2 text-center no-lists"
        style="width:100%;"
      >
        <v-card-title class="headline"
          >Nessun prodotto in questa lista</v-card-title
        >
      </v-card>
    </v-container>

    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<script>
import ListItem from "@/components/lists/ListItem";
import ListService from "~/service/listService";
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapActions } from "vuex";

export default {
  name: "ListDetail",
  props: {
    listId: { type: [String, Number], required: true }
  },
  data() {
    return {
      list: null
    };
  },
  components: {
    ListItem
  },
  mixins: [deliveryReactive],
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    async fetchList() {
      this.list = await ListService.getList(this.listId, true);
    },
    async removeFromList({ itemId, product }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await ListService.removeItemFromList(
          this.list,
          itemId,
          product.productId
        );
        this.list = newList;
      }
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${this.list.name}?`;

      let res = await this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list);

        this.list = res;
      }
    },
    async addAllToCart() {
      await this.addProductsFromWishlist(this.list.listId);
    },
    async updateList({ newQuantity, item }) {
      const res = await ListService.updateProductsInList(
        this.list.listId,
        item,
        newQuantity
      );
      this.list = res;
    },
    reload() {
      this.fetchList();
    }
  },
  created() {
    let _this = this;
    global.EventBus.$on("updatedList", newList => {
      _this.list = newList;
    });

    this.reload();
  },
  watch: {
    listId() {
      this.reload();
    }
  }
};
</script>
